body{
    font-family: 'Raleway', sans-serif;
}

.document-category-bg:hover {
    background-color: #0C2957;
  }

/* Other styles for the page */

.lazy-show-footer {
    opacity: 0;
    transition: opacity 1s ease; /* Change the duration and easing to your preference */
  }
  
.lzay-show-footer.show {
opacity: 1;
}
  