@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;400;600;800&display=swap');

@layer base {
  html {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    background-color: #fefffb;
  }
}

strong {
  font-weight: bold;
}

.container {
  margin: 0 auto;
  padding: 0 4rem;
}

.container.narrow {
  max-width: 960px;
  margin: 0 auto
}

#accordion .item {
  transition: 2s;
}

#accordion .accContent {
  max-height: 0;
  overflow: hidden;
  transition: all 1s ease;
}

#accordion .active .accContent {
  max-height: 100vh;
  transition: all 3s ease-out;
}

#accordion .active .accContent p {
  transition: 2s;
}

#accordion img {
  transition: .5s;
}

#accordion .active img {
  transform: rotate(45deg);
}

#accordion .item h3 {
  position: relative;
  transition: 1s;
  left: 0;
}

#accordion .item:hover h3 {
  left: 1rem;
  transition: .5s;
}

.contentArea p,
.contentArea blockquote,
.contentArea h3,
.contentArea ul,
.contentArea .contentBlock {
  width: 50%;
  margin-left: 50%;
  padding: 0 8rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #fff;
  margin-bottom: 1.5rem;
}

.contentAreaMobile p {
  margin-bottom: 1.5rem;
}

.contentArea blockquote {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 600;
}

.contentArea h3 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: bold;
  margin-top: 2rem;
}

.contentArea .gallery {
  display: block;
  width: 50%;
  text-align: right;
  float: right;
}

.contentArea .gallery img {
  padding: 4rem 0;
  width: 100%;
}

.contentArea .gallery.img01 {
  text-align: right;
}

.contentArea .gallery.img01 img {
  width: calc(100% - 1rem);
  float: right;
}

.contentArea .gallery.img02 img {
  width: calc(100% - 1rem);
}

.contentArea p strong {
  font-weight: 600;
}

.contentArea p a {
  border-bottom: 1px solid #fff;
}

.contentArea ul {
  margin-bottom: 2rem;
}

.contentArea li {
  list-style-type: square;
  margin-left: 1rem;
  padding-bottom: .5rem;
}

.pageFoot a {
  transition: .5s;
}

.pageFoot a img {
  position: relative;
  transition: .5s;
  right: 0;
}

.pageFoot a:hover img {
  right: -1rem;
  transition: .5s;
}

.pageFoot a strong {
  border-bottom: 2px solid transparent;
  transition: .5s;
  margin-left: .75rem;
}

.pageFoot a:hover strong {
  border-bottom: 2px solid #fff;
}

.pageFoot.dark a:hover strong {
  border-bottom: 2px solid #000;
}

.lastNews a .thumb {
  transition: .5s;
}

.lastNews a:hover .thumb {
  opacity: .5;
  transition: .5s;
}

.lastNews a span {
  transition: .5s;
}

.lastNews a:hover span {
  bottom: 4rem;
}

.jobDesc li {
  list-style: disc;
  margin-left: 2rem;
}

.thumb {
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;
  cursor: pointer;
}

.thumb-news-slide {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.thumb img {
  height: auto;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
}

.thumb .linkedin {
  position: absolute;
  max-width: 32px;
  top: 2rem;
  right: 0;
  left: auto;
  opacity: 0;
  transition: .5s;
}

.thumb:hover .linkedin {
  opacity: 1;
}


.mainNav {
  display: flex;
}

.mainNav > li {
  padding: 1rem 2rem;
  margin: 0;
  position: relative;
}

.mainNav > li a {
  font-weight: 600;
  font-size: 1.25rem;
  color: #fff;
}

.subNav {
  display: none;
  position: absolute;
  min-width: 24rem;
  padding-top: 3rem;
  margin-top: -10px;
}

.subNav li a {
  font-size: 2rem;
}

.mainNav li:hover .subNav {
  display: block;
}

.mainNav li a {
  overflow: hidden;
  position: relative;
}

.mainNav li a::after {
  content: '';
  position: absolute;
  left: 0; bottom: -3px;
  height: 1.5px;
  background-color: #fff;
  width: 0;
  transition: width .4s;
}

.mainNav li a:hover::after,
.mainNav li a:focus::after {
  width: 100%;
}

#header .headWrap {
  z-index: 5;
  position: relative;
}

.overlay {
  height: 0;
  background-color: rgba(0,0,0,.75);
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  transition: .5s ease-in-out;
  opacity: 0;
}

.overlay[data-item="chisiamo"] {
  background-color: rgba(2,31,84,.9);
  height: 80vh;
  opacity: 1;
}

.overlay[data-item="news"] {
  background-color: rgba(1,54,72,.9);
  height: 80vh;
  opacity: 1;
}

.overlay[data-item="network"] {
  background-color: rgba(0,37,107,.9);
  height: 80vh;
  opacity: 1;
}

.overlay[data-item="edu"] {
  background-color: rgba(40,1,107,.9);
  height: 80vh;
  opacity: 1;
}


.parent-div {
  filter: brightness(50);
}
/* Initial position of title, description, and arrow */
.title,
.description,
.svg-icon {
  transition: transform 0.3s; /* Smooth transition effect */
}

/* Transformed position on hover */
.parent-div:hover .title {
  transform: translateY(-20px); /* Moves the title 20 pixels upward */
}

.parent-div:hover .description {
  transform: translateY(-20px); /* Moves the description 20 pixels upward */
}

.parent-div:hover .svg-icon {
  transform: translateX(20px); /* Moves the arrow SVG 20 pixels to the right */
}

.pagniation-active-bg {
  background-color: #01c3af;
}